<template>
  <SortableBlock
    class="x-form"
    :style="styles"
    :header-actions="headerActions"
    :sorting-data="sortingData"
  >
    <template slot="header">
      <div class="x-form__header">
        <div class="indent-actions" v-if="checkType('text_editor')">
          <font-awesome-icon
            v-if="
              data[settings.value_key].indent < 10 &&
              maxIndent >= data[settings.value_key].indent
            "
            class="mr-3"
            icon="custom-indent-right"
            @click="
              dataChanged(
                {
                  text: data[settings.value_key].text,
                  indent: ++data[settings.value_key].indent,
                },
                'text_editor'
              )
            "
          />
          <font-awesome-icon
            v-if="data[settings.value_key].indent > 0"
            class="mr-3"
            icon="custom-indent-left"
            @click="
              dataChanged(
                {
                  text: data[settings.value_key].text,
                  indent: --data[settings.value_key].indent,
                },
                'text_editor'
              )
            "
          />
        </div>
      </div>
    </template>

    <template slot="content">
      <div class="x-form__content">
        <MediaLibrary
          v-if="showPopup"
          modal-id="xform-sotrable-media-library"
          :type="mediaLibraryType"
          custom-class="gray-header"
          v-bind:show-popup="showPopup"
          @get-media="getMedia"
          @close="onClose"
        />

        <div class="text-editor" v-if="checkType('color_field')">
          <FormulateInput
            :key="`color-input-${sortingData.index}`"
            type="text-editor"
            name="text-editor"
            :value="data[settings.value_key].text"
            v-model="data[settings.value_key].text"
            :extra-configs="data.editorProps"
            @changed="
              dataChanged({ text: $event, bgColor: hexToRgba }, 'color_field')
            "
          />
        </div>

        <div class="text-editor" v-if="checkType('text_editor')">
          <!--
                    There is a problem with @changed="dataChanged( { text: $event, indent: data[settings.value_key].indent }, 'text_editor' )"
                    It takes huge amount of resourses and because of it rich text editor gets remounted all the time, it may not be the source of the problem but without it it starts to work normal.
                    
                    <FormulateInput
                        type="text-editor"
                        name="text-editor"
                        v-model="data[settings.value_key].text"
                        @changed="dataChanged( { text: $event, indent: data[settings.value_key].indent }, 'text_editor' )"
                        :extra-configs="data.editorProps"
                    />-->
          <FormulateInput
            :key="`texteditor-${sortingData.index}`"
            type="text-editor"
            name="text-editor"
            :value="data[settings.value_key].text"
            v-model="data[settings.value_key].text"
            :extra-configs="data.editorProps"
          />
        </div>

        <div class="definition" v-if="checkType('definition')">
          <h6 class="mb-4 text-center">{{ settings.definition.label }}</h6>
          <Button
            class="w-100 mb-4"
            v-if="settings.definition.pastBtn.show"
            :variant="settings.definition.pastBtn.variant"
            :label="settings.definition.pastBtn.label"
            @click="pastDefinition"
          />
          <div class="value">
            <FormulateInput
              type="text"
              name="text"
              disabled
              :key="data[settings.value_key]"
              :value="data[settings.value_key]"
              :hidden="true"
            />
            <FormulateInput
              type="text"
              name="text"
              disabled
              :key="`${data[settings.value_key]}-title`"
              :value="data.title"
            />

            <font-awesome-icon
              v-show="data[settings.value_key]"
              class="times-circle"
              icon="custom-times-circle"
              @click="settings.definition.remove(sortingData.index)"
            />
          </div>
        </div>

        <div class="video" v-if="checkType('video')">
          <VideoInputPlayer
            :button-label="data[settings.value_key].labels.upload_video"
            margin="-25px"
            :is-multiple="false"
            :key="data[settings.value_key]._id"
            :text="data[settings.value_key].title"
            :video="data[settings.value_key].media"
            :link="data[settings.value_key].link"
            :action="false"
          >
            <Button
              class="btn-slateblue"
              :label="data[settings.value_key].labels.select_video"
              padding="11px 20px"
              icon="custom-upload"
              icon-position="right"
              @click="showPopup = true"
            />
          </VideoInputPlayer>
        </div>

        <div class="image" v-if="checkType('image')">
          <ImageInput
            @click="showPopup = true"
            :button-label="data[settings.value_key].labels.select_image"
            :image="data[settings.value_key].media"
            :text="data[settings.value_key].title"
            :link="data[settings.value_key].link"
            margin="-25px"
            :is-multiple="false"
            :key="data[settings.value_key]._id"
            :action="false"
          >
            <Button
              class="btn-slateblue"
              :label="data[settings.value_key].labels.select_image"
              padding="11px 20px"
              icon="custom-upload"
              icon-position="right"
              @click="showPopup = true"
            />
          </ImageInput>
        </div>
        <div class="table" v-if="checkType('table')">
          <table>
            <thead>
              <tr>
                <th v-for="(col, colKey, colIndex) in localTable.columns" :key="`editor-thead-col-${colIndex}`">
                  <FormulateInput @input="tableUpdated" type="text" v-model="localTable.columns[colKey]" :value="col" />
                  <font-awesome-icon
                    class="danger-icon"
                    icon="custom-trash"
                    @click="removeCol(colKey)"
                  />
                </th>
                <th :rowspan="getRowSpan(localTable)">
                  <div class="add">
                    <button class="add" @click="addCol">
                      +
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowKey, rowIndex) in localTable.rows" :key="`editor-row-${rowIndex}`">
                <td v-for="(col, colKey, colIndex) in row" :key="`editor-row-${rowIndex}-col-${colIndex}`">
                  <FormulateInput @input="tableUpdated" type="text" v-model="localTable.rows[rowKey][colKey]" :value="col" />
                  <font-awesome-icon
                    v-if="(colIndex + 1) == Object.keys(row).length"
                    class="danger-icon"
                    icon="custom-trash"
                    @click="removeRow(rowKey)"
                  />
                </td>
              </tr>
              <tr>
                <td :colspan="getColSpan(localTable)">
                  <div class="add">
                    <button class="add" @click="addRow">
                      +
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>

    <template slot="footer">
      <div class="x-form__footer">
        <div class="rgba-picker" v-if="checkType('color_field')">
          <FormulateInput
            class="mr-4"
            type="color"
            v-model="color"
            @change="
              dataChanged(
                { text: data[settings.value_key].text, bgColor: hexToRgba },
                'text_editor'
              )
            "
          />
          <FormulateInput
            class="mr-4"
            type="range"
            min="0"
            max="100"
            v-model="alpha"
            @change="
              dataChanged(
                { text: data[settings.value_key].text, bgColor: hexToRgba },
                'text_editor'
              )
            "
          />
          <span>{{ alpha }}%</span>
        </div>
      </div>
    </template>
  </SortableBlock>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { debounce } from "@/services/debounce";

import Button from "@/components/Buttons/Button.vue";
import SortableBlock from "@/components/Blocks/SortableBlock";
import VideoInputPlayer from "@/components/Media/VideoInputPlayer";
import ImageInput from "@/components/Media/ImageInput";
import MediaLibrary from "@/components/MediaGallery/MediaLibrary";

import { library } from "@fortawesome/fontawesome-svg-core";
import { customIndentRight, customIndentLeft } from "@/assets/icons";
import { customTrash } from "@/assets/icons";
library.add(customIndentRight, customIndentLeft, customTrash);

export default {
  name: "XFormSortable",
  components: {
    Button,
    SortableBlock,
    VideoInputPlayer,
    ImageInput,
    MediaLibrary,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    headerActions: {
      type: Array,
      default: () => [],
    },
    sortingData: {
      type: Object,
      default: () => ({ index: 0, amount: 0 }),
    },
    settings: {
      type: Object,
      default: () => {},
    },
    maxIndent: {
      type: Number,
      default: 0,
    },
    updateKey: {
      type: Number,
      default: Date.now(),
    },
    extraKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      color: "",
      alpha: "0",
      showPopup: false,
      localTable: { columns: {}, rows: {} }
    };
  },
  computed: {
    ...mapState("helper", {
      clipboard: "clipboard",
    }),
    hexToRgba() {
      return (
        "rgba(" +
        parseInt(this.color.slice(-6, -4), 16) +
        "," +
        parseInt(this.color.slice(-4, -2), 16) +
        "," +
        parseInt(this.color.slice(-2), 16) +
        "," +
        this.alpha +
        ")"
      );
    },
    styles() {
      const indent = this.data[this.settings.value_key]?.indent
        ? this.data[this.settings.value_key].indent
        : 0;

      return {
        "--indent": indent * 2 + "rem",
      };
    },
    mediaLibraryType() {
      if (this.data.kind === 3) {
        return "video";
      }

      return "image";
    },
    textEditorValue() {
      if (this.checkType("text_editor")) {
        return this.data[this.settings.value_key].text;
      }

      return "";
    },
  },
  methods: {
    ...mapMutations("_module", {
      updateContentBlock: "updateContentBlock",
    }),
    ...mapMutations("helper", {
      clearClipboard: "clearClipboard",
    }),
    getValue(val) {
      return { val: val };
    },
    pastDefinition() {
      let data = {
        index: this.sortingData.index,
        data: this.clipboard,
      };

      this.$emit("changed", data);
      this.clearClipboard();
    },
    addVideo(files, filesType) {
      this.$emit("addVideo", files, filesType, this.sortingData.index);
    },
    addImage(files, filesType) {
      this.$emit("addImage", files, filesType, this.sortingData.index);
    },
    rgbaToHex() {
      const color = this.data[this.settings.value_key].bgColor;
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");

      return `#${(
        (1 << 24) +
        (parseInt(rgba[0]) << 16) +
        (parseInt(rgba[1]) << 8) +
        parseInt(rgba[2])
      )
        .toString(16)
        .slice(1)}`;
    },
    rgbaToAlpha() {
      const color = this.data[this.settings.value_key].bgColor;
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");

      return rgba[3];
    },
    checkType(key) {
      if (
        this.settings &&
        this.settings[key] &&
        this.settings[key].type !== undefined &&
        this.settings.type_key &&
        this.data[this.settings.type_key] === this.settings[key].type
      ) {
        return true;
      }
      return false;
    },
    dataChanged(value, key) {
      let data = {
        index: this.sortingData.index,
        [this.settings.value_key]: value,
        [this.settings.type_key]: this.settings[key].type,
      };
      this.$emit("changed", data);
    },
    generateLink(link) {
      if (!link) return "";
      return `//${window.location.host}${link}`;
    },
    getMedia(value) {
      this.$emit("getMedia", value, this.sortingData.index);
    },
    onClose(status) {
      this.showPopup = status;
    },
    addCol() {
      const colKey = Date.now();
      this.$set(this.localTable.columns, `col_${colKey}`, '');
      
      if (!Object.keys(this.localTable.rows).length) return;

      Object.keys(this.localTable.rows).forEach( rowKey => {
        if (this.localTable.rows[rowKey]) this.$set(this.localTable.rows[rowKey], `col_${colKey}`, '');
      } );
    },
    addRow() {
      let newKey = Date.now();
      let rowPayload = {};

      Object.keys(this.localTable.columns).forEach( colKey => {
        rowPayload[colKey] = '';
      } );

      this.$set(this.localTable.rows, `row_${newKey}`, rowPayload);

      this.tableUpdated();
    },
    removeRow(key) {
      this.$delete(this.localTable.rows, key);

      this.tableUpdated();
    },
    removeCol(key) {
      this.$delete(this.localTable.columns, key);

      if (Object.keys(this.localTable.rows).length) {
        for (const [rowKey, columnsInRow] of Object.entries(this.localTable.rows)) {
          if (columnsInRow) this.$delete(this.localTable.rows[rowKey], key);
        }
      }

      this.tableUpdated();
    },
    tableUpdated() {
      debounce(
        () => {
          this.$emit('changed', {
            index: this.sortingData.index,
            [this.settings.value_key]: this.localTable,
            [this.settings.type_key]: this.settings['table'].type
          });
        },
        500,
        `module-field-${this.sortingData.index}`
      );
    },
    resetLocalTable() {
      this.localTable = { columns: {}, rows: {} };
    },
    getRowSpan(localTable) {
      if (localTable?.rows && typeof localTable.rows == 'object' && Object.keys(localTable.rows).length) return Object.keys(localTable.rows).length;

      return 1;
    },
    getColSpan(localTable) {
      if (localTable?.columns && typeof localTable.columns == 'object' && Object.keys(localTable.columns).length) return Object.keys(localTable.columns).length;

      return 1;
    },
    setLocalTable() {
      this.localTable = JSON.parse(JSON.stringify(this.data.data));
    }
  },
  mounted() {
    if (this.checkType("color_field")) {
      this.color = this.rgbaToHex();
      this.alpha = this.rgbaToAlpha();
    }

    if (this.checkType("table")) this.setLocalTable();
  },
  destroyed() {
    this.resetLocalTable();
  },
  watch: {
    "data.color_field.bgColor"() {
      if (this.checkType("color_field")) {
        this.color = this.rgbaToHex();
        this.alpha = this.rgbaToAlpha();
      }
    },
    "data.kind"() {
      if (this.checkType('table')) this.setLocalTable();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/formulate-input/text";

.x-form {
  margin-left: var(--indent) !important;

  .table {
    display: flex;
    overflow-x: auto;

    table {
      flex: 1 0 auto;

      .add {
        width: 100%;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          font-weight: 800;
          font-size: 18px;
          text-align: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: lime;
          color: #fff;
          bordeR: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          &:focus-within,
          &:focus-visible,
          &:active {
            outline: none;
          }
        }
      }
    }

    thead {
      background-color: #fcfcfc;
    }

    table, th, td {
      border: 1px solid black;
      vertical-align: middle;
    }

    th,
    td {
      position: relative;

      .formulate-input {
        margin-bottom: 0px;
      }

      .danger-icon {
        right: -10px;
        bottom: -5px;
        margin: auto;
        color: red;
        position: absolute;
        background-color: #fff;
        cursor: pointer;
        z-index: 2;
      }
    }

    th {
      .danger-icon {
        bottom: unset;
        top: 0;
      }
    }
  }

  &__header {
    .indent-actions {
      display: flex;
      svg {
        cursor: pointer;
        color: $light_text;
        font-size: 1.5rem;
      }
    }

    &__actions {
      display: flex;

      .icon-wrapper {
        &:not(:last-child) {
          border-right: 1px solid $light_gray;
        }

        .icon {
          padding: 0 10px;
          cursor: pointer;
          transition: all 0.3s ease 0s;

          &:hover {
            opacity: 0.7;
          }

          svg {
            color: $light_text;

            &.chevron-up,
            &.chevron-down {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }

  &__content {
    .definition {
      .value {
        position: relative;

        .times-circle {
          position: absolute;
          right: 0.75rem;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.25rem;
          color: $light_text;
          cursor: pointer;
          @include transition--ease-out;

          &:hover {
            filter: brightness(80%);
          }
        }
      }
    }
  }

  &__footer {
    .rgba-picker {
      display: flex;
      align-items: center;

      * {
        margin-bottom: 0;
      }
    }
  }
}

.video {
  ::v-deep {
    .base-card {
      box-shadow: unset;
      &:hover {
        box-shadow: unset;
      }
    }
  }
}
</style>
