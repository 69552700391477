<template>
  <div class="right-sidebar">
    <div class="right-sidebar__close d-lg-none">
      <font-awesome-icon icon="arrow-left" @click="closeSidebar" />
    </div>

    <div class="right-sidebar__list">
      <CollapsibleSearchCard
        :search-placeholder="labels.search"
        :list="definitionList"
        :header-label="labels.definitions"
        :header-btn="createBtn(openCreateDefinitionPopup)"
        :emit-search="true"
        @searchInCollpasedCard="searchDefinitions"
      >
        <Pagination
          :total-rows="definitionsPagination.items.total"
          :per-menu="definitionsPagination.items.limit || 20"
          :current-pagination="definitionsPagination.pages.current"
          @paginationChanged="paginationChanged"
          :pages-on-screen="3"
        />
      </CollapsibleSearchCard>
      <CollapsibleSearchCard
        :search-placeholder="labels.search"
        :list="cardList"
        :header-label="labels.cards"
        :header-btn="createBtn(openCreateCardPopup)"
        :emit-search="true"
        @searchInCollpasedCard="searchCards"
      >
        <Pagination
          :total-rows="cardsPagination.items.total"
          :per-menu="cardsPagination.items.limit || 20"
          :current-pagination="cardsPagination.pages.current"
          @paginationChanged="cardsPaginationChanged"
          :pages-on-screen="3"
        />
      </CollapsibleSearchCard>
      <div v-if="showLinksChanger" class="module-side-line">
        <Button
          :label="labels.links_changer"
          variant="btn-purple"
          :styles="{ padding: '8px 15px', fontWeight: 700 }"
          :action="() => $emit('display-links-changer')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CollapsibleSearchCard from "@/components/Collapsible/CollapsibleSearchCard";
import Pagination from "@/components/Helpers/Pagination";
import Button from "@/components/Buttons/Button";

import { mapActions, mapMutations } from "vuex";

export default {
  name: "RightSideBar",
  components: {
    CollapsibleSearchCard,
    Pagination,
    Button
  },
  props: {
    closeSidebar: {
      type: Function,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
    modulePageData: {
      type: Object,
      required: true,
    },
    showLinksChanger: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      page: 1,
      cardList: [],
      cardsPagination: {
        items: {
          begin: 0,
          end: 0,
          limit: 0,
          total: 0
        },
        pages: {
          current: 0,
          hasNext: false,
          hasPrev: false,
          next: 0,
          prev: 0,
          total: 0
        }
      },
      cardsSearch: '',
      definitionList: [],
      definitionsPagination: {
        items: {
          begin: 0,
          end: 0,
          limit: 0,
          total: 0
        },
        pages: {
          current: 0,
          hasNext: false,
          hasPrev: false,
          next: 0,
          prev: 0,
          total: 0
        }
      },
      definitionSearch: ''
    };
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("card", {
      getCards: "getList",
    }),
    ...mapMutations("helper", {
      copyToClipboard: "copyToClipboard",
    }),
    openCreateCardPopup() {
      if (this.$route.name !== "create-card")
        this.$router.push({ name: "create-card" });
    },
    openCreateDefinitionPopup() {
      if (this.$route.name !== "create-definiton")
        this.$router.push({ name: "create-definition" });
    },
    copyToClipboardMt(id) {
      console.log('Copy definitions to clipboard triggered, id is:', id);
      this.definitionList.forEach((item) => {
        console.log('Going through cards list (definitions included as well), item is:', item);
        if (item._id === id) {
          console.log('Id match!', item);
          this.copyToClipboard(item._id);
          this.addNotification({
            variant: "success",
            msg: this.labels.copying_to_clipboard_successful,
          });
        } else {
          console.log(`Checked ${item._id} ${id}`);
        }
      });
    },
    getCardsMt(page, kind) {
      let data = { kind: kind, cardsMap: true };
      if (page) data.page = page;
      if (kind == 0 && this.$route.query.cardsPage) data.page = this.$route.query.cardsPage;
      if (kind == 1 && this.$route.query.definitionsPage) data.page = this.$route.query.definitionsPage; 
      if (kind == 0 && this.cardsSearch?.length) data.search = this.cardsSearch;
      if (kind == 1 && this.definitionSearch?.length) data.search = this.definitionSearch;

      this.getCards(data).then((res) => {
        if (res.success) {
          let resultList = res.data.data;

          if (data.kind == 0) {
            this.cardList = [];
            this.cardsPagination.items = res.data.items;
            this.cardsPagination.pages = res.data.pages;
          } else {
            this.definitionList = [];
            this.definitionsPagination.items = res.data.items;
            this.definitionsPagination.pages = res.data.pages;
          }
          
          if (resultList.length >= 1) {
            resultList.forEach((item) => {
              if (item.kind === 0)
                this.cardList.push({
                  _id: item._id,
                  label: item.title,
                  actions: [
                    {
                      type: "icon",
                      icon: "custom-external-link",
                      link: { name: "edit-card", params: { id: item._id } },
                    },
                  ],
                });

              if (item.kind === 1)
                this.definitionList.push({
                  _id: item._id,
                  label: item.title,
                  actions: [
                    {
                      type: "button",
                      label: "Copy",
                      variant: "btn-navy",
                      action: this.copyToClipboardMt,
                    },
                    {
                      type: "icon",
                      icon: "custom-external-link",
                      link: {
                        name: "edit-definition",
                        params: { id: item._id },
                      },
                    },
                  ],
                });
            });
          }
        } else if (res.error) {
          this.addNotification({
            variant: "danger",
            msg: res.error,
            labels: this.labels,
          });
        }
      });
    },
    createBtn(action) {
      if (this.modulePageData.hasRole("super") || this.modulePageData.isAuthor)
        return { variant: "btn-purple", label: "New", action: action };

      return {};
    },
    paginationChanged(page){
      this.$router.replace({ name: this.$route.name, params: this.$route.params, query: { ...this.$route.query, definitionsPage: page } })
    },
    cardsPaginationChanged(page){
      this.$router.replace({ name: this.$route.name, params: this.$route.params, query: { ...this.$route.query, cardsPage: page } })
    },
    searchCards(title) {
      this.cardsSearch = title;
    },
    searchDefinitions(title) {
      this.definitionSearch = title;
    }
  },
  mounted() {
    this.getCardsMt(1, 0);
    this.getCardsMt(1, 1);
  },
  watch: {
    '$route.query.definitionsPage'(newValue) {
      this.getCardsMt(newValue, 1);
    },
    '$route.query.cardsPage'(newValue) {
      this.getCardsMt(newValue, 0);
    },
    cardsSearch() {
      if (this.$route.query.cardsPage == 1) {
        this.getCardsMt(1, 0);
      } else {
        this.$router.replace({ name: this.$route.name, params: this.$route.params, query: { ...this.$route.query, cardsPage: 1 } });
      }
    },
    definitionSearch() {
      if (this.$route.query.definitionsPage == 1) {
        this.getCardsMt(1, 1);
      } else {
        this.$router.replace({ name: this.$route.name, params: this.$route.params, query: { ...this.$route.query, definitionsPage: 1 } });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.right-sidebar {
  .module-side-line {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    border-bottom: 1px solid #e7e7e7;
  }

  &__close {
    padding: 15px 25px 15px;
    svg {
      display: block;
      text-align: center;
      margin: 0 0 0 auto;
      padding: 7px;
      width: 40px;
      height: 30px;
      border-radius: 5px;
      background-color: $purple;
      color: $white;
      transition: all 0.3s ease;
      box-shadow: none;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 6px 2px rgba(93, 52, 206, 0.5);
      }
    }
  }

  &__list {
    overflow-y: auto;
    @include transition--ease-out;
    height: 100%;

    @media (max-width: 992px) {
      height: calc(100% - 60px);
    }

    &::-webkit-scrollbar {
      width: $scrollbar_width;
    }

    &::-webkit-scrollbar-track {
      background: $light_gray;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $focus_color;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }

    ::v-deep {
      .lable-button-item__label {
        width: 80px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 15px;
      }
    }
  }

  ::v-deep {
    .page__pagination {
      display: flex;
      align-items: center;
      margin-top: 15px;
      width: 100%;
      justify-content: center;
    }
  }
}
</style>
