<template>
  <div class="editor">
    <LeftSideBar
      ref="editor-left-sidebar"
      :labels="labels"
      :close-sidebar="contentSidebarData.leftSidebarAction"
      :class="[
        'editor__left-sidebar',
        { active: contentSidebarData.leftStatus },
      ]"
      :module-page-data="modulePageData"
    />

    <SvgLoader v-if="!dataLoaded" :loading="true"  :bgColor="'transparent'" :width="'100%'"/>

    <div v-if="dataLoaded" class="editor__content">
      <h4 class="mb-1 text-center" v-if="modulePageData.contentKind == 1">
        Linked content
      </h4>
      <ReviewInProgressBlock
        v-if="
          ['author', 'reviewer'].includes(editorMode) &&
          (modulePageData.isReviewer || modulePageData.isAuthor) &&
          waitingContentResponse &&
          modulePageData.isInReview
        "
        :text="labels.review_in_progress"
        :button-labels="{ notes: contentNotesLabel, add_note: labels.add_note }"
        :backgroundColor="`${colorsHtml.background_2}`"
        :reviewNotes="goToReviewNotes"
      />
      <XFormActions
        v-if="['history', 'pending'].includes(modulePageData.status)"
        class="mb-4"
        :data="XFormActionsData"
        @changed="dropdownChanged"
      />
      <div class="module-section" v-for="(block, aIdx) in testModuleContent" :key="`${aIdx}_${contentUpdateKey}`">
        <XFormSortable
          class="mb-4"
          :class="{ 'content-disabled': !contentEditable }"
          :sorting-data="{ index: aIdx, amount: testModuleContent.length }"
          :data="block"
          :max-indent="getMaxIndent(testModuleContent[aIdx - 1])"
          :header-actions="sortingBlockActions"
          :settings="settings"
          :extra-key="modulePageData.status"
          @changed="updateContentBlock"
          @addVideo="addFiles"
          @getMedia="getMedia"
        />
        <div
          v-if="contentEditable && modulePageData.isModuleContentPresent && (aIdx+1 != testModuleContent.length)"
          class="buttons-wrapper"
        >
          <div class="buttons">
            <Button
              variant="btn-outline-purple"
              :label="labels.text"
              @click="addBlock(0, aIdx)"
            />
            <Button
              variant="btn-outline-purple"
              class="ml-4"
              :label="labels.definition"
              @click="addBlock(1, aIdx)"
            />
            <Button
              variant="btn-outline-purple"
              class="ml-4"
              :label="labels.table"
              @click="addBlock(5, aIdx)"
            />
          </div>
          <div class="buttons mt-4">
            <button class="btn btn-outline-success" @click="addBlock(2, aIdx)">
              {{ labels.color_field }}
            </button>
            <button class="btn btn-outline-success ml-4" @click="addBlock(3, aIdx)">
              {{ labels.video }}
            </button>
            <button class="btn btn-outline-success ml-4" @click="addBlock(4, aIdx)">
              {{ labels.image }}
            </button>
          </div>
        </div>
      </div>
      <div
          v-if="contentEditable && modulePageData.isModuleContentPresent"
          class="buttons-wrapper"
        >
          <div class="buttons">
            <Button
              variant="btn-outline-purple"
              :label="labels.text"
              @click="addBlock(0, aIdx)"
            />
            <Button
              variant="btn-outline-purple"
              class="ml-4"
              :label="labels.definition"
              @click="addBlock(1, aIdx)"
            />
            <Button
              variant="btn-outline-purple"
              class="ml-4"
              :label="labels.table"
              @click="addBlock(5, aIdx)"
            />
          </div>
          <div class="buttons mt-4">
            <button class="btn btn-outline-success" @click="addBlock(2, aIdx)">
              {{ labels.color_field }}
            </button>
            <button class="btn btn-outline-success ml-4" @click="addBlock(3, aIdx)">
              {{ labels.video }}
            </button>
            <button class="btn btn-outline-success ml-4" @click="addBlock(4, aIdx)">
              {{ labels.image }}
            </button>
          </div>
        </div>
      <div v-if="modulePageData.cancelReviewPossible" class="buttons-wrapper">
        <Button
          variant="btn-outline-purple"
          :label="labels.cancel_review"
          @click="cancelReviewMD"
        />
      </div>
    </div>

    <RightSideBar
      ref="editor-right-sidebar"
      :labels="labels"
      :close-sidebar="contentSidebarData.rightSidebarAction"
      :class="[
        'editor__right-sidebar',
        { active: contentSidebarData.rightStatus },
      ]"
      :module-page-data="modulePageData"
      :show-links-changer="showLinksChanger"
      @display-links-changer="openLinksChanger"
    />

    <router-view
      name="create_card"
      modal-id="create-card"
      kind="card"
      :route-back="{ name: 'module-editor' }"
      :title="labels.create_new_card"
      :custom-fields="cardCustomFields"
      :required-data-set="[
        'title',
        'content_0',
        'content_1',
        'kind',
        'referer',
      ]"
      :data-set-values="{ kind: 0, referer: this.moduleId }"
      @entity-created="cardCreated"
    />

    <router-view
      name="create_definition"
      modal-id="create-definiton"
      kind="card"
      :route-back="{ name: 'module-editor' }"
      :title="labels.create_new_definition"
      :custom-fields="definitionCustomFields"
      :required-data-set="[
        'title',
        'content_0',
        'content_1',
        'kind',
        'referer',
      ]"
      :data-set-values="{ kind: 1, referer: this.moduleId }"
      @entity-created="cardCreated"
    />

    <router-view
      name="content_notes"
      modal-id="content_notes"
      kind="notes"
      :route-back="{ name: 'module-editor' }"
      :title="labels.add_note"
      :title-placeholder="labels.title"
      :text-placeholder="labels.description"
      :required-fields="['title', 'text']"
      :required-data-set="['title', 'text']"
      @entity-created="addNewNote"
    />

    <router-view
      name="create_sub_module"
      modal-id="create-sub-module"
      :modulePageData="modulePageData"
      @saved="subModuleCreated"
    />

    <router-view
      name="history"
      modal-id="history"
      :route-back="{ name: 'module-editor' }"
    />
    <router-view
      name="request"
      modal-id="request"
      :route-back="{ name: 'module-editor' }"
    />
    <router-view
      name="search"
      modal-id="search"
      :route-back="{ name: 'module-editor' }"
    />
    <!-- Link changed for linked content type -->
    <Popup
        v-if="showLinksChanger"
        size="lg"
        custom-class="gray-header"
        :onHide="closeLinkChanger"
        :on-close="closeLinkChanger"
        :scrollable="true"
        :toggle-status="linkChangerShown"
        :modal-id="`links-changer`"
        :key="linkChangerRefreshKey"
    >
      <template v-slot:header>
          <div>
              {{ labels.links_changer }}
          </div>
      </template>
      <template v-slot:body>
        <div class="links-changer">
          <div v-for="(link, linkUrl) in linkChangerLinks" :key="`${linkUrl}-replaced-link`" class="replaced-link">
            <div class="originals">
              <h6 v-html="`${link.label} -&nbsp;`"></h6>
              <a :href="linkUrl" target="_blank">
                {{ labels.original_link }}
              </a>
            </div>
            <div class="replaced">
              <label>
                {{ labels.replaced_link }}:
              </label>
              <div class="replaced-selector">
                <input type="text" v-model="linksToReplace[linkUrl]" />
                <span class="or-label">
                  {{ labels.or }}
                </span>
                <Button
                  variant="btn-purple"
                  :label="labels.select_module"
                  @click="extraFieldsForModuleSelector = { originalLink: linkUrl }, linkChangerShown = false, showModuleSelector = true"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
          <div class="controls">
              <button @click="saveReplacedLinksFn" class="btn btn-success">
                  {{ labels.save }}
              </button>
              <button @click="closeLinkChanger" class="btn btn-danger">
                  {{ labels.close }}
              </button>
          </div>
      </template>
    </Popup>
    <!-- Module selector in this case only used for link changer purposes for now -->
    <ModulesSelector
      v-if=showModuleSelector
      :show-popup="true"
      @module-selelector-close="showModuleSelector = false"
      @module-selected="applyLinkOfModule"
      :extra-fields-to-emit="extraFieldsForModuleSelector"
    />
  </div>
</template>

<script>
import LeftSideBar from "@/views/Dashboard/Module/Editor/LeftSideBar.vue";
import RightSideBar from "@/views/Dashboard/Module/Editor/RightSideBar.vue";
import XFormSortable from "@/components/Forms/XFormSortable";
import XFormActions from "@/components/Forms/XFormActions";
import Button from "@/components/Buttons/Button.vue";
import ReviewInProgressBlock from "@/components/Blocks/ReviewInProgressBlock.vue";
import colorsHtml from "/colors.config.json";
import SvgLoader from "@/components/Preloaders/SvgLoader";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Popup from "@/components/Popups/Popup.vue";

export default {
  name: "Editor",
  components: {
    LeftSideBar,
    RightSideBar,
    XFormSortable,
    XFormActions,
    Button,
    ReviewInProgressBlock,
    SvgLoader,
    Popup,
    ModulesSelector: () => import('@/components/Helpers/ModulesSelector.vue')
  },
  props: {
    contentSidebarData: {
      type: Object,
      required: true,
    },
    modulePageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      colorsHtml: colorsHtml,
      updateKey: Date.now(),
      sortingBlockActions: [
        { icon: "chevron-up", action: this.sortUpMethod },
        { icon: "chevron-down", action: this.sortDownMethod },
        { icon: "custom-trash", action: this.removeBlock },
      ],
      dataLoaded: false,
      linkChangerShown: false,
      linksToReplace: {},
      showModuleSelector: false,
      extraFieldsForModuleSelector: {},
      linkChangerRefreshKey: Date.now() // Bad solution but I have no other ideas for this case now, without this change either not display new link return from module selector, either bugs modal backdrop
    };
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    ...mapState("helper", {
      clipboard: "clipboard",
      user_id: "user_id",
      admin_roles: "admin_roles",
    }),
    ...mapState("card", {
      cardList: "cardList",
      cardsMap: "cardsMap",
    }),
    ...mapState("_module", {
      stateSingleData: "stateSingleData",
      moduleContent: "moduleContent",
      editorMode: "editorMode",
      waitingContentResponse: "waitingContentResponse",
      singleData: "singleData",
      contentUpdateKey: "contentUpdateKey"
    }),
    showLinksChanger() {
      return this.modulePageData.isAuthor && this.singleData?.contentMeta?.kind == 1 ? true : false;
    },
    countChanges() {
      return this.$store.getters["_module/countContentChanges"];
    },
    contentLength(){
      return this.moduleContent.length
    },
    settings() {
      return {
        type_key: "kind",
        value_key: "data",
        text_editor: { type: 0 },
        color_field: { type: 2 },
        video: { type: 3 },
        image: { type: 4 },
        definition: {
          type: 1,
          label: this.labels.select_definition,
          remove: (index) => this.updateContentBlock({ data: "", index }),
          pastBtn: {
            variant: "btn-green",
            label: this.labels.paste_definition,
            action: this.pastDefinition,
            show: this.clipboard,
          },
        },
        table: {
          type: 5
        }
      };
    },
    cardCustomFields() {
      return [
        {
          type: "text",
          name: "title",
          label: this.labels.title,
          class: "mb-4",
        },
        {
          type: "textarea",
          name: "content_0",
          label: this.labels.question,
          class: "mb-4",
          validationName: this.labels.question,
        },
        {
          type: "textarea",
          name: "content_1",
          label: this.labels.answer,
          validationName: this.labels.answer,
        },
      ];
    },
    definitionCustomFields() {
      return [
        {
          type: "text",
          name: "title",
          label: this.labels.title,
          class: "mb-4",
        },
        {
          type: "text",
          name: "content_0",
          label: this.labels.definition_title,
          class: "mb-4",
          validationName: this.labels.definition_title,
        },
        {
          type: "textarea",
          name: "content_1",
          label: this.labels.definition,
          validationName: this.labels.definition,
        },
      ];
    },
    XFormActionsData() {
      return {
        dropdownList: {
          1: "20.12.2021 - 19:20 by user X",
          2: "20.12.2021 - 19:20 by user Y",
        },
        btns: [
          {
            label: this.labels.notes,
            variant: "btn-orange",
            action: () => console.log("Notes action"),
          },
          {
            label: this.labels.revert,
            variant: "btn-green",
            action: () => console.log("Revert action"),
          },
        ],
      };
    },
    videoData() {
      return {
        labels: {
          upload: this.labels.upload,
          upload_video: this.labels.upload_video,
          select: this.labels.select,
          select_video: this.labels.select_video,
        },
        media: "",
        link: "",
        title: "",
        _id: 0,
        action: () => this.deleteMDMedia(),
      };
    },
    imageData() {
      return {
        labels: {
          upload: this.labels.upload,
          upload_image: this.labels.upload_image,
          select: this.labels.select,
          select_image: this.labels.select_image,
        },
        media: "",
        link: "",
        title: "",
        _id: 0,
        action: () => this.deleteMDMedia(),
      };
    },
    testModuleContent() {
      let output = [];
      let index = 0;
      
      this.moduleContent.forEach((item) => {
        if (item.kind === 1 && this.cardsMap[item.data]) {
          output.push({
            ...item,
            title: this.cardsMap[item.data].title,
          });
        } else if (item.kind === 3) {
          item.data.action = (index) =>
            this.deleteMDMedia(item.data.video, "video", index);
          output.push(item);
        } else if (item.kind === 0) {
          item.editorProps = {
            hideBgColorPicker: true,
            hideColorPicker: true,
            forceTextColor: this.presetTextColor(),
            aiPopupId: ++index
          };
          output.push(item);
        } else if (item.kind === 2) {
          item.editorProps = {
            hideBgColorPicker: true,
            hideColorPicker: true,
            forceTextColor: this.presetTextColor(),
          };
          output.push(item);
        } else output.push(item);
      });

      return output;
    },
    moduleId() {
      return this.$route.params.id;
    },
    contentEditable() {
      // Case for author editor mode
      if (
        this.editorMode === "author" &&
        this.modulePageData.isAuthor &&
        !this.modulePageData.isReviewRequested &&
        this.modulePageData.status === "change" &&
        ![-1, 1].includes(this.modulePageData.contentKind)
      )
        return true;

      // Case for reviewer editor mode
      if (
        this.editorMode === "reviewer" &&
        this.modulePageData.isReviewer &&
        this.modulePageData.isInReview &&
        this.modulePageData.status === "change" &&
        ![-1, 1].includes(this.modulePageData.contentKind)
      )
        return true;

      // For other cases content is not supposed to be editable
      return false;
    },
    contentNotesLabel() {
      return this.singleData?.content?.notes?.length ? `${this.labels.notes} (${this.singleData.content.notes.length})` : this.labels.notes;
    },
    linkChangerLinks() {
      /* eslint-disable */
      let result = {};

      if (!this.showLinksChanger) return result;

      if (this.moduleContent?.length && Array.isArray(this.moduleContent)) {
        let allTextContent =  '';

        this.moduleContent.forEach(contentSection => {
          if ([0, 2].includes(contentSection.kind) && contentSection.data?.text?.length)  {
            allTextContent += contentSection.data.text;
          }
        });

        if (allTextContent.length) {
          // Find links
          const regex = /\<a((?!href).)*href\=\"([^\"]+)\"\>(((?!\<\/a>).)*)\<\/a\>/gm;

          // Alternative syntax using RegExp constructor
          // const regex = new RegExp('\\<a((?!href).)*href\\=\\"([^\\"]+)\\"\\>(((?!\\<\\\/a>).)*)\\<\\\/a\\>', 'gm')

          // Reset lastIndex if this regex is defined globally
          // regex.lastIndex = 0;

          let m;

          while ((m = regex.exec(allTextContent)) !== null) {
              // This is necessary to avoid infinite loops with zero-width matches
              if (m.index === regex.lastIndex) {
                  regex.lastIndex++;
              }
              
              // The result can be accessed through the `m`-variable.
              const originalLink = m[2];
              const linkLabel = m[3];

              result[originalLink] = {
                replaceWith: this.singleData?.replacedLinks?.[originalLink] ? this.singleData.replacedLinks[originalLink] : originalLink,
                label: linkLabel
              };

              this.linksToReplace[originalLink] = this.singleData?.replacedLinks?.[originalLink] ? this.singleData.replacedLinks[originalLink] : originalLink;
          }
        }
      }

      return result;
    }
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("_module", {
      getModule: "getSingle",
      rejectReview: "rejectReview",
      saveReplacedLinks: "saveReplacedLinks"
    }),
    ...mapMutations("helper", {
      clearClipboard: "clearClipboard",
    }),
    ...mapMutations("_module", {
      addContentBlock: "addContentBlock",
      removeContentBlock: "removeContentBlock",
      updateContentBlock: "updateContentBlock",
    }),
    ...mapActions("_module", {
      addMedia: "addMedia",
      deleteMedia: "deleteMedia",
      reviewApprove: "reviewApprove",
      update: "update",
    }),
    applyLinkOfModule(moduleId, mBundleId, extraFields) {
      this.showModuleSelector = false;
      
      if (!moduleId?.length) {
        this.linkChangerShown = true;

        return;
      }

      this.$set(this.linksToReplace, extraFields.originalLink, this.$router.resolve({
        name: 'bundle-module',
        params: {
          monetization_bundle_id: mBundleId,
          module_id: moduleId
        }
      }).href);

      this.linkChangerRefreshKey = Date.now();

      this.linkChangerShown = true;

      console.log('Updated this.linksToReplace', this.linksToReplace);
    },
    saveReplacedLinksFn() {
      console.log(this.linksToReplace);
      this.saveReplacedLinks({
        id: this.$route.params.id,
        payload: {
          replacedLinks: this.linksToReplace
        }
      })
        .then((res) => {
          console.log(res);
          if (res.success) {
            this.addNotification({
              variant: "success",
              msg: this.labels.saved
            });
            this.$emit('refresh-module');
            this.closeLinkChanger();
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    openLinksChanger() {
      this.linkChangerShown = true;
    },
    closeLinkChanger()  {
      this.linkChangerShown = false;
    },
    getMaxIndent(data) {
      if (
        data !== undefined &&
        data.kind === 0 &&
        data.data &&
        data.data.indent !== undefined
      ) {
        return data.data.indent;
      }
      return 0;
    },
    pastDefinition(index) {
      // this.updateContentBlock( { data: this.clipboard, index } );
      // this.clearClipboard();
      console.log("Clipboard:", this.clipboard);
      console.log("Index:", index);
    },
    sortDownMethod(index) {
      this.$sortDown(this.$store.state._module.moduleContent, index, this.$set);
    },
    sortUpMethod(index) {
      this.$sortUp(this.$store.state._module.moduleContent, index, this.$set);
    },
    addBlock(kind, insertAfter) {
      if (insertAfter || insertAfter === 0) insertAfter++;
      
      let data = "";
      if (kind === 0) data = { text: "", indent: 0 };
      if (kind === 2) data = { text: "", bgColor: "rgba(255,255,255,0)" };
      if (kind === 3) data = this.videoData;
      if (kind === 4) data = this.imageData;
      if (kind === 5) data = { columns: {}, rows: {} }
      this.addContentBlock({ kind, data, insertAfter });
      this.updateKey = Date.now();
    },
    removeBlock(index) {
      if (this.moduleContent[index].data.action) {
        this.moduleContent[index].data.action(index);
      }
      this.removeContentBlock(index);
    },
    dropdownChanged(value) {
      console.log(value);
    },
    subModuleCreated() {
      this.$refs["editor-left-sidebar"].getSubModulesMt(1);
    },
    cardCreated() {
      this.$refs["editor-right-sidebar"].getCardsMt(1);
    },
    addFiles(files, filesType, index) {
      console.log("Got files:", files);
      console.log("Got files type:", filesType);
      console.log("Got index:", index);
    },
    deleteMDMedia() {
      // let data = {
      //     id: this.stateSingleData._id,
      //     media: media,
      //     type: filesType
      // }
      // this.deleteMedia(data).then(res => {
      //     if (res.success) {
      //         if (filesType === 'video') this.updateVideos();
      //         // if (filesType === 'audio') this.updateAudios();
      //         if (this.moduleContent[index]) this.updateContentBlock( { data: this.videoData, index: index } );
      //         this.addNotification( { variant: 'success', msg: ['deleted'], labels: this.labels });
      //     }
      // });
    },
    updateVideos() {
      this.videos.videos = [];

      if (this.stateSingleData.videos) {
        this.stateSingleData.videos.forEach((video) => {
          this.videos.videos.push(video);
        });
      }

      this.updateKey = Date.now();
    },
    generateFileTitle(fileName) {
      return fileName.replace(/\.[^/.]+$/, "");
    },
    cancelReviewMD() {
      this.rejectReview({ id: this.stateSingleData._id })
        .then((res) => {
          if (res.success) {
            this.addNotification({
              variant: "success",
              msg: res.msg,
              labels: this.labels,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    goToReviewNotes() {
      if (this.$route.name !== "module-content-notes") {
        this.$router.push({
          name: "module-content-notes",
          query: { page: this.$route.query.page },
        });
      }
    },
    updateModule() {
      let data = {
        id: this.$route.params.id,
        payload: {}, // need add right data
      };

      this.update(data)
        .then((res) => {
          if (res.success) {
            console.log(res);
            this.addNotification({
              variant: "success",
              msg: res.msg,
              labels: this.labels,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    approveReview() {
      let data = {
        // need add right data
        id: "",
        content_id: "",
      };

      this.reviewApprove(data)
        .then((res) => {
          if (res.success) {
            this.addNotification({
              variant: "success",
              msg: res.msg,
              labels: this.labels,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    addNewNote() {
      console.log("addNewNote");
    },
    presetTextColor() {
      if (this.editorMode === "reviewer" && this.modulePageData.isInReview)
        return "#ff0000";

      if (this.editorMode === "author" && this.modulePageData.isInReview)
        return "#00b3ff";

      return `${this.colorsHtml.black}`;
    },
    dataLoadingFn(){
        setTimeout(() => {
          this.dataLoaded = true
        }, 1500);
        if(this.moduleContent.length > 0){
          this.dataLoaded = true
        }
    },
    getMedia(mediaObj, sectionSortingId) {
      const currentContent = { ...this.moduleContent[sectionSortingId] };

      this.updateContentBlock({
        data: {
          ...currentContent.data,
          ...{
            _id: mediaObj._id,
            title: mediaObj.title,
            link: mediaObj.link,
            media: mediaObj.media,
          },
        },
        index: sectionSortingId,
      });
    },
  },
  watch: {
    // editorMode: {
    // 	handler(value) {
    // 		if(value === 'author') {
    //             this.$root.$refs.modulePageContainer.getModuleMt();
    //         }
    // 	}
    // },
    // status: {
    // 	handler() {
    // 		this.$root.$refs.modulePageContainer.getModuleMt();
    // 	}
    // }

    contentLength: {
           handler () {
            this.dataLoadingFn()
           },
    }
  },
  mounted() {
    // this.updateVideos();
    // this.$root.$refs.modulePageContainer.getModuleMt();
    this.dataLoadingFn()
  },
  beforeRouteLeave(to, from, next) {
    if (!this.countChanges.saveBtnDisabled) {
      const answer = window.confirm(
        this.labels.do_you_really_want_to_leave_you_have_unsaved_changes
      );
      if (answer) {
        // RESET CHANGES
        this.$root.$refs.modulePageContainer.getModuleMt();
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.editor {
  position: relative;
  display: flex;

  .content-disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &__content {
    flex: 1 1 auto;
    padding: 25px;

    .module-section:not(:last-child) {
      .buttons-wrapper {
        margin-bottom: 24px;
      }
    }

    .buttons-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      &:nth-last-child(1) {
        margin-top: 24px;
      }

      .buttons {
        // width: 250px;
        display: flex;
        justify-content: space-around;
      }
    }
  }

  &__left-sidebar,
  &__right-sidebar {
    flex: 0 0 315px;
    position: sticky;
    top: 110px;
    height: calc(100vh - 111px);
    min-width: 315px;
    border-right: 1px solid $light_gray;
    background-color: $white;
  }

  &__left-sidebar {
    @media (max-width: 1200px) {
      position: fixed;
      left: -100%;
      top: 0;
      z-index: 1030;
      height: 100vh;

      &.active {
        left: 0px;
        height: calc(100vh - 74px);

        @media (min-width: 768px) {
          left: 50px;
          height: 100vh;
        }

        @media (min-width: 1200px) {
          left: 310px;
        }

        @media (min-width: 1600px) {
          left: 340px;
        }
      }
    }
  }

  &__right-sidebar {
    border-left: 1px solid $light_gray;

    @media (max-width: 992px) {
      position: fixed;
      left: -100%;
      top: 0;
      z-index: 1030;
      height: 100vh;

      &.active {
        left: 0px;
        height: calc(100vh - 74px);

        @media (min-width: 768px) {
          left: 50px;
          height: 100vh;
        }

        @media (min-width: 1200px) {
          left: 310px;
        }

        @media (min-width: 1600px) {
          left: 340px;
        }
      }
    }
  }

  .controls {
    display: flex;

    button:last-child {
      margin-left: 15px;
    }
  }

  .links-changer {
    .replaced-link {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      .originals,
      .replaced {
        display: flex;
        flex-direction: column;
        width: 100%;

        a {
          margin-bottom: 0px;
          margin-top: auto;

          &:hover {
            text-decoration: none;
          }
        }

        input {
          width: 100%;
          padding: 5px 10px;
          border: 0;
          background-color: rgba(0,0,0,0.05);
          
          &:focus-visible,
          &:focus-within {
            outline: none;
          }
        }
      }

      .originals {
        flex-direction: row;
        align-items: center;

        h6, a {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .replaced-selector {
        display: flex;
        flex-direction: column;
        align-items: center;

        > span {
          display: block;
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
