<template>
	<div :class="['collapsible-search-card', { collapsed }]">
		<div class="collapsible-search-card__header">
			<div
				class="label"
				:data-target="`#collapse-${headerLabel}`"
				data-toggle="collapse"
				@click="collapsed = !collapsed"
			>
				<font-awesome-icon :icon="collapsed ? 'plus' : 'minus'"/>
				{{ headerLabel }}
			</div>
			<Button
				:variant="headerBtn.variant"
				:label="headerBtn.label"
				:action="headerBtn.action"
				v-if="headerBtn && Object.keys(headerBtn).length && isAllowed"
			/>
		</div>
		<div class="collapsible-search-card__body collapse" :id="`collapse-${headerLabel}`">
			<FormulateInput
				type="search"
				name="search"
				class="custom-search-left"
				:placeholder="searchPlaceholder"
				@input="searchFn"
			/>
			<LabelButtonItem v-for="( item, idx ) in searchQuery" :key="idx" :data="item"/>
			<slot></slot>
		</div>
	</div>
</template>

<script>
import { debounce } from "@/services/debounce";
import LabelButtonItem from '@/components/Cards/LabelButtonItem';
import Button from '@/components/Buttons/Button.vue';

import { mapState } from 'vuex';

export default {
	name: 'CollapsibleSearchCard',
	components: {
		LabelButtonItem,
		Button,
	},
	props: {
		searchPlaceholder: {
			type: String,
			default: ''
		},
		headerLabel: {
			type: String,
			default: ''
		},
		headerBtn: {
			type: Object,
			default: () => {}
		},
		list: {
			type: Array,
			default: () => []
		},
		emitSearch: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			search: '',
			collapsed: true,
		}
	},
	methods: {
		searchFn(value) {
			debounce( () => {
				if (this.emitSearch) {
					this.$emit('searchInCollpasedCard', value);
				} else {
					this.search = value;
				}
			}, 500, `collapsible-search-card-${this._uid}` );
		}
	},
	computed: {
		...mapState( "helper", {
			user_id: 'user_id',
			admin_roles: 'admin_roles'
		}),
		...mapState( '_module', {
            singleData: 'singleData',
        }),
		isAllowed() {
			let flag = false;
            
			if (Array.isArray(this.admin_roles)) {
				for (let i = 0; i < this.admin_roles.length; i++) {
					if (['author', 'creator'].includes(this.admin_roles[i])) {
						this.singleData.authors?.forEach(author => {
							if (author._id === this.user_id || this.admin_roles[i] === 'creator') {
								flag = true;
							}
						})
					}
				}
			}

            return flag;
        },
        isSuper() {
			if (!Array.isArray(this.admin_roles)) return false;
			
			return this.admin_roles.includes('super');
        },
        isCreator() {
			if (!Array.isArray(this.admin_roles)) return false;
			
			return this.admin_roles.includes('creator');
        },
        isPermission() {
            return this.isAuthor || this.isSuper || this.isCreator;
        },
		searchQuery() {
			return this.list.filter( ( item ) => {
				return item.label.match( this.search );
			} );
		}
	},
}
</script>

<style lang='scss' scoped>

.collapsible-search-card {

	&:not(.collapsed) {
		border-bottom: 1px solid $light_gray;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		padding: 0 25px;
		border-bottom: 1px solid $light_gray;

		.label {
			font-size: .875rem;
			font-weight: 600;
			cursor: pointer;
			transition: all .3s ease 0s;

			&:hover {
				opacity: .7;
			}
		}

		button {
			padding: 5px 15px;
		}
	}

	&__body {
		.custom-search-left {
			padding: 25px;
			margin-bottom: 0;
		}
	}
}

</style>